.App {
  height: 100vh;
  /* grid container settings */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 
    'header'
    'main'
    'footer';
}

.App > header {
  grid-area: header;
}

.App > main {
  grid-area: main;
  padding: 15px 5px 10px 5px;
}

.App > footer {
  grid-area: footer;
}